import { ReactNode } from 'react';

type ContainerProps = {
  animationDuration: number;
  children: ReactNode;
  isFinished: boolean;
};

export default function Container({ animationDuration, children, isFinished }: ContainerProps) {
  return (
    <div
      className="pointer-events-none"
      style={{ opacity: isFinished ? 0 : 1, transition: `opacity ${animationDuration}ms linear` }}
      data-testid="loading-bar.container"
    >
      {children}
    </div>
  );
}
