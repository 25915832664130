import { ReactNode, createContext, useContext, useState } from 'react';

export type IncomeStatementsListParams = {
  filters: Record<string, string>;
  sortField: string;
  sortOrder: 1 | -1;
  page: number;
  rows: number;
};

type IncomeStatementsListParamsContextData = {
  incomeStatementsListParams: IncomeStatementsListParams;
  setIncomeStatementsListParams: (params: IncomeStatementsListParams) => void;
};

export const IncomeStatementsListParamsContext =
  createContext<IncomeStatementsListParamsContextData | null>(null);

export const useIncomeStatementsListParams = () => {
  const context = useContext(IncomeStatementsListParamsContext);

  if (context === null) {
    throw new Error(
      'The useIncomeStatementsListParams hook cannot be used outside of IncomeStatementsListParamsProvider'
    );
  }

  return context;
};

export const IncomeStatementsListParamsProvider = ({ children }: { children: ReactNode }) => {
  const [incomeStatementsListParams, setIncomeStatementsListParams] =
    useState<IncomeStatementsListParams>({
      filters: {},
      sortField: 'submission_date',
      sortOrder: 1,
      page: 1,
      rows: 10,
    });

  return (
    <IncomeStatementsListParamsContext.Provider
      value={{
        incomeStatementsListParams,
        setIncomeStatementsListParams,
      }}
    >
      {children}
    </IncomeStatementsListParamsContext.Provider>
  );
};
