import 'react-toastify/dist/ReactToastify.min.css';
import '@/styles/globals.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import Progress from '@/components/navigation/loading-bar/progress';
import { AppProvider } from '@/providers/index';
import { useProgressStore } from '@/store/use-progress-store';
import { NextPageWithLayout } from '@/types/layout-types';

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const setIsAnimating = useProgressStore((state: any) => state.setIsAnimating);
  const isAnimating = useProgressStore((state: any) => state.isAnimating);

  const router = useRouter();
  const getLayout = Component.getLayout ?? (page => page);

  useEffect(() => {
    const handleStart = () => {
      setIsAnimating(true);
    };

    const handleStop = () => {
      setIsAnimating(false);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);

  return (
    <SessionProvider session={pageProps.session}>
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <Progress isAnimating={isAnimating} />
          {getLayout(<Component {...pageProps} />)}
          <ToastContainer />
        </AppProvider>
      </QueryClientProvider>
    </SessionProvider>
  );
}

export default MyApp;
